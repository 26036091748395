import React from "react"
import { Component } from "react";


class LandingArticle extends Component{

    constructor(props){
        super(props);

        this.contextRef = React.createRef();
        this.descriptionRef = React.createRef();
    }

    ShowMore(e){
        if (this.descriptionRef.current.classList.contains('article-desc-text-auto')){
            e.target.innerText = "Read more";
            this.descriptionRef.current.classList.remove('article-desc-text-auto');
        } else {
            e.target.innerText = "Roll up";
            this.descriptionRef.current.classList.add('article-desc-text-auto');
        }
    }

    componentDidMount(){
        if ((this.descriptionRef.current.scrollHeight - this.descriptionRef.current.clientHeight) <= 0){
            let btn = this.contextRef.current.querySelector('.read-more-btn');
            btn.remove();
        }
    }

    render(){
        return(
            <article on className="article-item pb-6 pt-6">
                <h2 className="home_title text-primary-dark font-weight-800 letter-spacing-n2 mb-6">{this.props.item.title ?? ""}</h2>
                <div className="article-context" ref={this.contextRef}>
                    <div className="article-img-wrapper">
                        {<img className="article-img" src={this.props.item.image?.mediaItemUrl ?? ""} alt={this.props.item.image?.altText ?? ""}/>}
                    </div>
                    <div className="article-desc">
                        <div className="article-desc-text" ref={this.descriptionRef}>
                            {
                                this.props.item?.body?.split("\n").map((par => (
                                        <p className="article-pg">{par ?? ""}</p>
                                )))
                            }
                        </div>
                        <div>
                            <button className="read-more-btn text-primary-dark" onClick={(e) => this.ShowMore(e)}>Read More</button>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
}

export default LandingArticle
